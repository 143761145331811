import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Accessibility is becoming increasingly important. The `}<a parentName="p" {...{
        "href": "https://ec.europa.eu/social/main.jsp?catId=1202"
      }}>{`European Accessibility Act`}</a>{`
will prescribe binding requirements for products and services from 2025. This also increases the need for experts who support
companies in the accessible design of their websites and apps.`}</p>
    <p>{`But what distinguishes an accessibility expert? How do I know that someone actually has a clue or is just throwing around buzzwords?`}</p>
    <p>{`From my point of view, `}<strong parentName="p">{`recognized certifications`}</strong>{` play an important role here. In this article I would like to discuss the
advantages and disadvantages of accessibility certifications and venture a definition of what skills can be expected from
professionals.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFyePY2iYD/AP/EABoQAQADAAMAAAAAAAAAAAAAAAIDERIBIjL/2gAIAQEAAQUCdZjOWPN9TJymGq//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhMUFh/9oACAEBAAY/AhKitvPDJ//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBgZH/2gAIAQEAAT8hvo+Q9dKuGOHsEU4OEzKmOq+z/9oADAMBAAIAAwAAABBcP//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQMBAT8QajW0/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAchy//xAAcEAEAAwACAwAAAAAAAAAAAAABABExIbFBYXH/2gAIAQEAAT8QK0E9i3nxANYtnImd9TdQIfstKnEZbOFOWnK7mju5Sf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A woman in a wheelchair. She balances a laptop on her knees.",
          "title": "A woman in a wheelchair. She balances a laptop on her knees.",
          "src": "/static/ea4c2d3e22cf17ccecda03564bc0ae41/e5166/pexels-marcus-aurelius-woman-in-wheelchair.jpg",
          "srcSet": ["/static/ea4c2d3e22cf17ccecda03564bc0ae41/f93b5/pexels-marcus-aurelius-woman-in-wheelchair.jpg 300w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/b4294/pexels-marcus-aurelius-woman-in-wheelchair.jpg 600w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/e5166/pexels-marcus-aurelius-woman-in-wheelchair.jpg 1200w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/b17f8/pexels-marcus-aurelius-woman-in-wheelchair.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Marcus Aurelius / pexels.com`}</em></p>
    <p>{`Note: I earned the `}<a parentName="p" {...{
        "href": "https://www.incite.at/de/programm/certified-webaccessibility-expert/"
      }}>{`“Certified Web Accessibility Expert”`}</a>{`
certificate from UBIT Academy incite in 2019. In the beginning of 2023 I became an “IAAP Certified Professional in Accessibility
Core Competencies (CPACC)” (`}<a parentName="p" {...{
        "href": "https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/linked_in_profile"
      }}>{`credential at Credly`}</a>{`).`}</p>
    <h2>{`The Essential Skills of an Accessibility Expert`}</h2>
    <p>{`Accessibility experts should have `}<strong parentName="p">{`empathy for people with disabilities`}</strong>{`. They should have a sincere interest in the
everyday life of these people and try to put themselves in their respective situation:`}</p>
    <ul>
      <li parentName="ul">{`How do blind people surf the Internet with the help of a screen reader?`}</li>
      <li parentName="ul">{`Under what conditions can people with motor impairments operate a website using the keyboard alone?`}</li>
      <li parentName="ul">{`When is video and audio content accessible for deaf people?`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`An expert needs to know the `}<strong parentName="p">{`different user groups and assistive technologies`}</strong>{`. These include
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Screen_magnifier"
      }}>{`screen magnifiers`}</a>{`, `}<a parentName="p" {...{
        "href": "/forced-colors-mode.en/"
      }}>{`high contrast mode`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dEbl5jvLKGQ"
      }}>{`screen readers`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/perspective-videos/voice/"
      }}>{`speech input software`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/perspective-videos/keyboard/"
      }}>{`keyboards`}</a>{`, and many more.`}</p>
    <p>{`Knowledge of the relevant `}<strong parentName="p">{`standards and laws`}</strong>{` is another minimum requirement. This includes both the success criteria of
the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{`, the European standard
`}<a parentName="p" {...{
        "href": "https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf"
      }}>{`EN 301 549`}</a>{`, and relevant national
laws such as the Austrian
`}<a parentName="p" {...{
        "href": "https://www.usp.gv.at/gesetzliche-neuerungen/Bundesgesetzblatt/barrierefreiheitsgesetz.html"
      }}><span lang="de">{`Barrierefreiheitsgesetz (BaFG)`}</span></a>{`.`}</p>
    <p>{`In addition, accessibility experts should also have experience in `}<strong parentName="p">{`testing for accessibility`}</strong>{`. They must be able to assess
whether a website or app complies with specific criteria and point out solutions for any barriers found.`}</p>
    <h2>{`Accessibility Certifications`}</h2>
    <p>{`Various institutions offer certifications on the topic of accessibility. There are national certification bodies such as the
`}<a parentName="p" {...{
        "href": "https://www.incite.at/"
      }}>{`UBIT Academy incite`}</a>{` in Austria. They offer certification as
a `}<ItalicText mdxType="ItalicText">{`Certified WebAccessibility Expert`}</ItalicText>{`.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.accessibilityassociation.org"
      }}>{`International Association of Accessibility Professionals (IAAP)`}</a>{` offers several
certifications, including the `}<ItalicText mdxType="ItalicText">{`Certified Professional in Accessibility Core Competencies (CPACC)`}</ItalicText>{` and
the `}<ItalicText mdxType="ItalicText">{`Web Accessibility Specialist (WAS)`}</ItalicText>{`. Their aim is to support accessibility experts in building up
their expertise.`}</p>
    <p>{`What are the advantages and disadvantages of such certifications?`}</p>
    <h3>{`Advantages`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Increased credibility`}</strong>{`: Certification is proof that one has certain skills and experience. This underlines the credibility
of experts.`}</li>
      <li parentName="ul"><strong parentName="li">{`Uniform standard`}</strong>{`: In order to obtain certification, interested parties must demonstrate their knowledge of specific
content in examinations or individual interviews. Reputable institutions define a publicly accessible
`}<a parentName="li" {...{
          "href": "https://www.accessibilityassociation.org/resource/IAAP_CPACC_BOK_March2020"
        }}>{`body of knowledge`}</a>{` for this purpose, which makes
this content transparent.`}</li>
      <li parentName="ul"><strong parentName="li">{`Community of practice and knowledge exchange`}</strong>{`: IAAP supports the networking of accessibility experts with online forums,
webinars and various events.`}</li>
    </ul>
    <h3>{`Disadvantages`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Cost`}</strong>{`: Certification is not free. CPACC, for example, costs just under $500 (slightly less for members). Not everyone
can afford it. However, compared to many other certifications in the IT field, I find the price very moderate.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cognitive barrier`}</strong>{`: Often a certification consists of a multiple-choice test with countless questions. For example,
on the CPACC, you have to answer a full 100 questions in two hours. That's exhausting. For people with cognitive impairment,
that can also be an insurmountable barrier.`}</li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`From my point of view, accessibility certifications are a good thing. They provide uniform, transparent proof of concrete
skills. They help companies to distinguish real experts from frauds.`}</p>
    <p>{`Do all accessibility experts have to be certified? Of course not. There are other ways to prove your expertise. For example,
if you have been designing accessible websites or apps for many years and can show them as references.`}</p>
    <p>{`In any case, I am happy about my certifications. They represent key milestones in my career as an accessibility expert.
And `}<a parentName="p" {...{
        "href": "https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/linked_in_profile"
      }}>{`the badge`}</a>{` I earned just looks cool! 😉`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.accessibilityassociation.org/s/certification"
        }}>{`IAAP: Certification Overview`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.incite.at/de/programm/certified-webaccessibility-expert/"
        }}>{`Certified WebAccessibility Expert (Austria)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.edx.org/learn/web-accessibility/the-world-wide-web-consortium-w3c-introduction-to-web-accessibility"
        }}>{`W3C: Introduction to Web Accessibility`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      